import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        lastEpisode: {},
        blogsList: [],
        lastEpisodes: [],
        singleBlog: null,
        blogProgress: [],
        nextBlog: null
    }),

    mutations: {
        setLastEpisode(state, data) {
            state.lastEpisode = data;
        },
        setBlogsList(state, data) {
            state.blogsList = data;
        },
        setLastEpisodes(state, data) {
            state.lastEpisodes = data;
        },
        setSingleBlog(state, data) {
            state.singleBlog = data;
        },
        setBlogProgress(state, data) {
            state.blogProgress = data
        },
        setBlogProgressStorage(state, data) {
            localStorage.setItem('blogProgress', JSON.stringify(data.value))
            state.blogProgress = data
        },
        setNextBlog(state, data) {
            state.nextBlog = data
        }
    },

    getters: {
        lastEpisode: state => state.blogs,
        blogsList: state => state.blogsList,
        lastEpisodes: state => state.lastEpisodes,
        singleBlog: state => state.singleBlog,
        blogProgress: state => state.blogProgress,
        nextBlog: state => state.nextBlog
    },

    actions: {
        async getLastEpisode({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + '/articles/list?only=title,small_image,id&per_page=1')
                .then(({ data }) => {
                    commit('setLastEpisode', data.data[0])
                }).catch(e => {
                    console.log(e)
                })
        },
        async getAllBlogsList({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + '/articles/list?only=title,small_image,id&per_page=100&order=ASC')
                .then(({ data }) => {
                    commit('setBlogsList', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getSmallBlogsList({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + '/articles/list?only=title,id,small_image&per_page=5&order=ASC')
                .then(({ data }) => {
                    commit('setBlogsList', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getSingleBlog({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/articles/${id}/get`)
                .then(({ data }) => {
                    commit('setSingleBlog', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getNextBlog({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/articles/${id}/get`)
                .then(({ data }) => {
                    commit('setNextBlog', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
    }
}