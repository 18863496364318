<template>
  <div class="modal-backdrop" @mousedown.self="closeModal">
    <div class="popup-content display--flex">
      <div class="__inner display--flex">
        <div class="popup-left">
          <div class="popup-logo">
            <img
              src="@/assets/images/logo.svg"
              alt="WAY2WEI"
              class="for-index hidden-sm"
            />
          </div>
        </div>
        <div class="popup-right">
          <div class="popup-body display--flex">
            <div class="popup-logo">
              <img
                src="@/assets/images/logo-b.svg"
                alt="WAY2WEI"
                class="visible-xs"
              />
            </div>
            <button type="button" class="popup-close" @click="closeModal">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M4 4L12 12M20 20L12 12M12 12L20 4M12 12L4 20"
                  stroke="currentColor"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <slot>
              <div v-if="state == 1" class="popup-container">
                <div class="popup-title">{{ title }}</div>
                <form @submit.prevent>
                  <div class="form-group">
                    <label>ФИО*</label>
                    <input
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      error="name"
                      required
                    />
                    <errors field="name" :errors="errors"></errors>
                  </div>
                  <div class="form-group">
                    <label>Email*</label>
                    <input
                      v-model="form.email"
                      type="email"
                      class="form-control"
                      error="email"
                      required
                    />
                    <errors field="email" :errors="errors"></errors>
                  </div>
                  <div class="form-group">
                    <label>Телефон*</label>
                    <vue-tel-input
                      class="form-control"
                      v-model="unformatPhone"
                      @on-input="setTelephone"
                      error="phone"
                      required
                    ></vue-tel-input>
                    <errors field="phone" :errors="errors"></errors>
                  </div>
                  <div v-if="postField" class="form-group">
                    <label>Должность</label>
                    <input
                      v-model="form.position"
                      type="text"
                      class="form-control"
                      error="position"
                    />
                    <errors field="position" :errors="errors"></errors>
                  </div>
                  <div v-if="companyField" class="form-group">
                    <label>Компания*</label>
                    <input
                      v-model="form.company"
                      type="text"
                      class="form-control"
                      error="company"
                    />
                    <errors field="company" :errors="errors"></errors>
                  </div>

                  <div v-if="sertificatsField" class="form-group">
                    <label>Наличие сертификатов</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div v-if="expperienceField" class="form-group">
                    <label>Опыт работы</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div v-if="commentField" class="form-group">
                    <label>Комментарий</label>
                    <input
                      v-model="form.comment"
                      type="text"
                      class="form-control"
                      error="comment"
                    />
                    <errors field="comment" :errors="errors"></errors>
                  </div>

                  <div v-if="diagnostic" class="form-group">
                    <label>Численность персонала</label>
                    <input
                      v-model="form.staff_count"
                      type="number"
                      class="form-control"
                      error="staff_count"
                    />
                    <errors field="staff_count" :errors="errors"></errors>
                  </div>
                  <div v-if="diagnostic" class="form-group">
                    <label>Сфера компании</label>
                    <input
                      v-model="form.scope"
                      type="text"
                      class="form-control"
                      error="scope"
                    />
                    <errors field="scope" :errors="errors"></errors>
                  </div>

                  <private-checkboxes
                    @checkboxchange="(data) => (checkboxes = data)"
                  />

                  <div class="form-action btn-action display--flex">
                    <button
                      @click="sendData"
                      type="submit"
                      class="btn btn-accent"
                      :disabled="!isFieldsFull"
                    >
                      Отправить заявку
                    </button>
                  </div>
                </form>

                <div class="space"></div>
              </div>

              <div v-if="state == 2" class="popup-container">
                <div class="popup-title">Мы получили ваше сообщение</div>
                <p>Скоро мы вам ответим, спасибо за обращение!</p>
                <div class="btn-action display--flex">
                  <router-link
                    to="/"
                    @click="closeModal"
                    class="btn btn-accent btn-sm"
                    >На главную</router-link
                  >
                  <button
                    @click="sendAgain"
                    type="button"
                    class="btn btn-o btn-sm"
                  >
                    Отправить еще раз
                  </button>
                </div>
              </div>

              <div v-if="state == 3" class="popup-container">
                <div class="popup-title">Что-то пошло не так</div>
                <p>Попробуйте еще раз</p>
                <div class="btn-action display--flex">
                  <button
                    @click="sendAgain"
                    type="button"
                    class="btn btn-o btn-sm"
                  >
                    Отправить еще раз
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRef } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useReCaptcha } from "vue-recaptcha-v3";
import useErrors from "../../mixins/useErrors";
import Errors from "../technical/Errors.vue";
import ymTrigger from "./ymTrigger";
import { computed } from "vue";
import PrivateCheckboxes from "../form-elements/private-checkboxes/PrivateCheckboxes.vue";

export default {
  components: { Errors, PrivateCheckboxes },
  name: "pop-up-form",
  props: {
    title: String,
    companyField: {
      type: Boolean,
      default: false,
    },
    postField: {
      type: Boolean,
      default: false,
    },
    commentField: {
      type: Boolean,
      default: false,
    },
    partnerField: {
      type: Boolean,
      default: false,
    },
    sertificatsField: {
      type: Boolean,
      default: false,
    },
    expperienceField: {
      type: Boolean,
      default: false,
    },
    diagnostic: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "Consult",
    },
    productId: {
      type: Number,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
    partnerId: {
      type: Number,
    },
    comment: {
      type: String
    }
  },
  setup(props, context) {
    const store = useStore(),
      { executeRecaptcha, recaptchaLoaded } = useReCaptcha(),
      { errors, setErrors, clearCustomErrors } = useErrors(),
      type = toRef(props, "type"),
      productId = toRef(props, "productId"),
      isPartner = toRef(props, "isPartner"),
      partnerId = toRef(props, "partnerId");
    const isFieldsFull = computed(
      () =>
        form.name &&
        form.email &&
        unformatPhone.value &&
        checkboxes.value.oferta &&
        checkboxes.value.personalData
    );
    const form = reactive({
      name: "",
      company: "",
      position: "",
      email: "",
      phone: "",
      comment: props.comment,
      staff_count: "",
      scope: "",
    });
    const checkboxes = ref({
      oferta: false,
      personalData: false,
      ad: false,
    });
    const state = ref(1),
      unformatPhone = ref("");

    const closeModal = () => {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
      context.emit("closeModal");
    };
    let setTelephone = (...data) => {
      if (data[1]?.valid) {
        data[2].classList.add("valid-form");
        data[2].parentNode.classList.add("valid-form");
      } else {
        data[2].classList.remove("valid-form");
        data[2].parentNode.classList.remove("valid-form");
      }
      if (data[1]?.number) {
        form.phone = data[1].number;
      }
    };
    let sendAgain = () => {
      state.value = 1;
    };

    onMounted(() => {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    });

    const sendData = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha("help");
      let data = {
        name: form.name,
        email: form.email,
        phone: form.phone,
        comment: form.comment,
        advert_accept: checkboxes.ad,
      };

      if (type.value !== "TestModal" && type.value !== "PartnerModal") {
        data = {
          ...data,
          unformatPhone: unformatPhone.value,
          recaptcha_token: token,
          title: type.value,
        };

        if (productId.value) {
          data.product_id = productId.value;
        }

        store
          .dispatch("sendForm", data)
          .then(() => {
            state.value = 2;
            ymTrigger(type);
          })
          .catch((error) => {
            if (error?.response.status == 422) {
              setErrors(error.response.data);
              clearCustomErrors();
            } else {
              state.value = 3;
            }
          });
        return;
      }
      if (type.value == "PartnerModal") {
        if (isPartner.value) {
          store
            .dispatch("sendPartnerRequest", {
              ...data,
              partner_id: +partnerId.value,
            })
            .then(() => (state.value = 2))
            .catch((error) => {
              if (error?.response.status == 422) {
                setErrors(error.response.data);
                clearCustomErrors();
              } else {
                state.value = 3;
              }
            });
          return;
        }
        store
          .dispatch("sendExpertRequest", {
            ...data,
            teacher_id: +partnerId.value,
          })
          .then(() => (state.value = 2))
          .catch((error) => {
            if (error?.response.status == 422) {
              setErrors(error.response.data);
              clearCustomErrors();
            } else {
              state.value = 3;
            }
          });
        return;
      }

      store
        .dispatch("sendTestForm", {
          ...data,
          company: form.company,
          recaptcha_token: token,
          title: type.value,
        })
        .then(() => {
          // eslint-disable-next-line no-undef
          ym(61610965, "reachGoal", "form-pdf-download");
          context.emit("sendTestForm", form.email);
          closeModal();
        })
        .catch((error) => {
          if (error?.response.status == 422) {
            setErrors(error.response.data);
            clearCustomErrors();
          } else {
            state.value = 3;
          }
        });
    };

    return {
      closeModal,
      sendData,
      unformatPhone,
      setTelephone,
      sendAgain,
      errors,
      setErrors,
      clearCustomErrors,
      state,
      form,
      checkboxes,
      isFieldsFull,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-info {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin: 10px;
}

.popup-container {
  margin-right: 35px;
}

.popup-content {
  width: 80%;
  min-height: 80%;
  background-color: var(--color-light);
}

.popup-content .__inner {
  flex: 1;
}

.popup-left {
  position: relative;
  flex: 1;
  background: linear-gradient(180deg, #313554 0%, #3e4262 100%);
}

.popup-left::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./../../assets/images/hero-green.svg);
  background-repeat: no-repeat;
  background-position: left bottom -10px;
  background-size: 81vw auto;
}

.popup-logo {
  position: absolute;
  display: block;
  top: 50px;
  left: 132px;
  width: 555px;
  height: 40px;
  text-align: left;
}

.popup-right {
  flex: 1;
  background-image: url(./../../assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: right -16px bottom -11px;
  background-size: 81vw auto;
}

.popup-body {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 600px;
  height: 100%;
  padding: 50px 0 50px 70px;
  font-size: 28px;
  line-height: 34px;
}

.popup-body p {
  margin: 0 0 30px 0;
}

.popup-close {
  position: absolute;
  display: flex;
  top: 54px;
  right: 32px;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  padding: 0;
  color: var(--color);
  background: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
}

.popup-close:hover {
  color: var(--accent);
}

.popup-close svg {
  width: 100%;
  height: 100%;
}

.popup-title {
  margin-bottom: 30px;
  font-size: 46px;
  line-height: 51px;
}

.popup-body form {
  padding: 35px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(182, 196, 214, 0.5);
}

.popup-body form .form-group + .form-group {
  margin-top: 20px;
}

.popup-body form .form-group + .form-action {
  margin-top: 35px;
}

@media (max-width: 1024px) {
  .popup-left {
    display: none;
  }
}

@media (max-width: 768px) {
  .popup-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .popup-body form .form-group + .form-action {
    margin-top: 25px;
  }

  .popup-logo {
    top: 32px;
    left: 32px;
  }

  .popup-container {
    margin-right: 0;
    padding-top: 73px;
    height: 100%;
  }

  .popup-close {
    top: 32px;
    right: 32px;
    width: 32px;
    height: 32px;
  }

  .popup-body {
    align-items: center;
    width: 100%;
    padding: var(--xs-continer-gap);
  }

  .popup-title {
    font-size: 36px;
    line-height: 43px;
  }

  .space {
    height: 15px;
  }
}

.checkboxes {
  font-size: 1.05rem;
  word-spacing: 0.9%;
  margin: 15px 0 9px 0;

  label {
    padding-left: 26px;
    position: relative;
  }

  label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-color);
    border-radius: 3px;
    margin-right: 10px;
  }

  input {
    height: 0;
    width: 0;
  }

  input:checked ~ label::after {
    content: "";
    background-image: url("./../../assets/images/icons/check.svg");
    background-size: cover;
    position: absolute;
    top: 3px;
    left: 0.5px;
    height: 19px;
    width: 19px;
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
}
</style>