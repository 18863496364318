import axios from "axios";

export default function () {
	let currentVersion = localStorage.getItem("version");

	const checkVersion = () => {
		setInterval(() => {
			axios.get(`${process.env.VUE_APP_FRONT}/check/version`).then(({ data }) => {
				if (!currentVersion) {
					currentVersion = data.version;
					return
				}
				if (data.version !== currentVersion) {
					currentVersion = data.version;
					localStorage.setItem("version", data.version)
					location.reload();
				}
			})
		}, process.env.VUE_APP_VERSION_TIMEOUT)
	}
	return { checkVersion }
}