<template>
  <Header/>
  <router-view/>
  <Footer/>
  <popup-technical-work
      v-if="showTechnicalModal"
      @closeModal="showTechnicalModal = false"
      :title="`На сайте ведутся техничекие работы`"
  ></popup-technical-work>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import {useHead} from "@vueuse/head";
import {ref} from "@vue/reactivity";
import {onBeforeMount, onMounted, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import axios from "axios";
import useCheckVersion from "./mixins/useCheckVersion";
import useCheckStatusTechnicalWork from "./mixins/useCheckTechnicalWork";
import popupTechnicalWork from "@/components/popup-technical-work/PopupTechnicalWork.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    popupTechnicalWork
  },
  provide: {
    backUrl: process.env.VUE_APP_URL,
  },

  setup() {
    const store = useStore();
    const {checkVersion} = useCheckVersion();
    const {checkStatusTechnicalWork} = useCheckStatusTechnicalWork();
    useHead({
      title:
          "WAY2WEI – первая в России платформа по оценке и развитию эмоционального интеллекта для HR, T&D, руководителей и сотрудников.",
      meta: [
        {
          property: "og:description",
          content:
              "Сокращаем время и экономим деньги на поиске, удержании, развитии ключевых сотрудников, помогая раскрыть их потенциал ",
        },
        {
          property: "og:keywords",
          content:
              "WAY2WEI, эмоциональный интеллект, развитие эмоционального интеллекта, эмоциональный интеллект платформа, формирование эмоционального интеллекта, эмоциональный интеллект в бизнесе, эмоциональный интеллект сотрудников, определить эмоциональный интеллект",
        },
      ],
    });
    let products = ref([]),
        basketCount = ref(0),
        showTechnicalModal = ref(false),
        currentStatusTechnicalWork = ref('')

    let setBasketCount = () => {
      products.value.forEach((item) => {
        if (item.isChecked) {
          basketCount.value += parseInt(item.product_count);
        }
      });
      localStorage.setItem("basketCount", basketCount.value);
      store.commit("products/setBasketProducts", basketCount.value);
    };

    axios.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error.message === 'Network Error'){
            showTechnicalModal.value = true
          }
          if (error.response) {
            checkStatusTechnicalWork()
          }
        });

    onBeforeMount(() => {
      // Запись токена авторизации
      let token = document.cookie.match(new RegExp("ei-token=([^;]*)"));

      if (token) {
        // Замена кода палочки на палочку
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token[1].replace("%7C", "|")}`,
        };
        store.commit("user/setUserData", {
          token: `Bearer ${token[1].replace("%7C", "|")}`,
        });
      }
      store.dispatch("products/getGeneralTags");
    });
    onMounted(() => {
      if (window.location.search) {
        document.cookie = `utm_query=${window.location.search}; max-age=604800`;
      }
      let storageProducts = localStorage.getItem("cartProducts");
      if (storageProducts) {
        products.value = JSON.parse(storageProducts);
        setBasketCount();
      }
      let blogProg = localStorage.getItem("blogProgress");
      if (blogProg && blogProg !== "undefined" && blogProg !== "null") {
        store.commit("blog/setBlogProgress", JSON.parse(blogProg));
      }
      store.dispatch("blog/getLastEpisode");

      store
          .dispatch("user/getCurrentProfile")
          .catch(() => store.commit("user/clearUserProfileData"));

      let userData = JSON.parse(localStorage.getItem('user'))
      if (userData) {
        store
            .dispatch("user/getCurrentUser")
            .then(() => {
              store.dispatch('setYmUid')
            })
      }
      currentStatusTechnicalWork.value = localStorage.getItem("statusTechnicalWork");
      checkVersion();
    });

    watch(currentStatusTechnicalWork, () => {
      if (currentStatusTechnicalWork.value !== '200') {
        showTechnicalModal.value = true
      }
    })


    return {products, basketCount, setBasketCount, showTechnicalModal};
  },
};
</script>


<style lang="scss">
@import "./assets/css/kf";
@import "./assets/css/common";

.grecaptcha-badge {
  visibility: hidden;
}

.special-color-1 .b24-widget-button-inner-item,
.special-color-1 .b24-crm-button-icon {
  background: black !important;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

/*
 * Containers
 * ------------------
 */
:root {
  --gap: 30px;
  --color: #353747;
  --color-dark: #313554;
  --color-light: #edf2f8;
  --color-light-2: #959abb;
  --accent: #01af08;
  --accent-l: #0cbf13;
  --border-color: #d6d7dd;
  --xs-continer-gap: 30px;
}

body {
  color: var(--color);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .menu-is-open {
    position: fixed;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  html {
    overflow-x: hidden !important;
    touch-action: none;
    -ms-touch-action: none;
  }
}

.hero {
  min-height: auto;
  margin: 0;
  padding: 140px 0 60px;
  background-color: var(--color-light);
  background-image: url(./assets/images/hero.svg);
  background-repeat: no-repeat;
  background-position: top 13px right;
  background-size: auto 400px;
  overflow: hidden;
}

.form-control[type="search"] {
  padding-right: 40px;
  background-image: url(./assets/images/icons/search-icon.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.container {
  width: 100%;
  max-width: 1110px;
}

@media (max-width: 1190px) {
  .container {
    padding: 0 calc(var(--xs-continer-gap) / 1.7);
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 var(--xs-continer-gap);
  }
}

/* BG & COLORS*/
.gradient-d {
  background: linear-gradient(180deg, #313554 0%, #3e4262 100%);
}

.bg-d {
  background-color: var(--color);
}

.bg-light {
  background-color: var(--color-light);
}

.bg-white {
  background-color: #ffffff;
}

.bg-accent {
  background-color: var(--accent);
}

.bg-accent-l {
  background-color: var(--accent-l);
}

.color-d {
  color: var(--color);
}

.color-accent {
  color: var(--accent);
}

.color-accent-l {
  color: var(--accent-l);
}

.color-white {
  color: #ffffff;
}
</style>
