import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        product: null,
        basketProducts: null,
        popularProducts: null,
        customProducts: null,
        generalTags: null,
        categories: null,
        productsForBusket: null
    }),

    mutations: {
        setProduct(state, data) {
            state.product = data;
        },
        setBasketProducts(state, data) {
            state.basketProducts = data
        },
        setPopularProducts(state, data) {
            state.popularProducts = data
        },
        setGeneralTags(state, data) {
            state.generalTags = data
        },
        setCategories(state, data) {
            state.categories = Object.values(data)
        },
        setCategoriesProducts(state, data) {
            let categoryIndex = state.categories.findIndex((category) => category.title === data.tag)
            state.categories[categoryIndex].products = data.data
        },
        setCustomProducts(state, data) {
            state.customProducts = data
        },
        setProductsForBusket(state, data) {
            state.productsForBusket = data
        }
    },

    getters: {
        product: state => state.product,
        popularProducts: state => state.popularProducts,
        generalTags: state => state.generalTags,
        categories: state => state.categories,
        productsForBusket: state => state.productsForBusket
    },

    actions: {
        async getProduct({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/${id}/get`)
                .then(({ data }) => {
                    commit('setProduct', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getProductBySlug({ commit }, slug) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/slug/${slug}/get`)
                .then(({ data }) => {
                    commit('setProduct', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getPopularProducts({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/popular/list?only=id,slug,back_middle_image,back_image,price,label_people,label_duration,name,label_description`)
                .then(({ data }) => {
                    commit('setPopularProducts', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getGeneralTags({ commit }) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/tags/generals`)
                .then(({ data }) => {
                    commit('setGeneralTags', data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getGeneralCategories({ commit }, name) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/tags/product/${name}/get`)
                .then(({ data }) => commit('setCategories', data.data))
                .catch(e => {
                    console.log(e)
                })
        },
        async getProductsByTag({ commit }, tags) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/all?only=id,name,slug,back_middle_image,back_image,price,label_people,label_duration,label_description&tags=${tags.mainTag.replace("&", "%26")},${tags.tag.replace("&", "%26")}`)
                .then((data) => {
                    commit('setCategoriesProducts', { tag: tags.tag, data: data.data.data })
                }).catch(e => {
                    console.log(e)
                })
        },
        async getComplexProduct({ commit }, id) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/packets/${id}/get`)
                .then(({ data }) =>
                    commit('setProduct', data.data)).catch(e => {
                        console.log(e)
                    })
        },
        async getComplexProductBySlug({ commit }, slug) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/packets/slug/${slug}/get`)
                .then(({ data }) =>
                    commit('setProduct', data.data)).catch(e => {
                        console.log(e)
                    })
        },
        async getCustomProducts({ commit }, tag) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/list/?only=id,name,slug,back_middle_image,back_image,price,label_people,label_duration,label_description&tags=${tag.replace("&", "%26")}`)
                .then((data) => {
                    commit('setCustomProducts', data.data.data)
                }).catch(e => {
                    console.log(e)
                })
        },
        async getProductsForBusket({ commit }, ids) {
            await axios
                .get(process.env.VUE_APP_API_URL + `/products/list?filter[IN|id]=${ids.join(",")}&only=id,name,price,slug`)
                .then((data) => {
                    commit('setProductsForBusket', data.data.data)
                })
        },
    }
}