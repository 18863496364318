<template>
  <footer>
    <div class="container">
      <div v-if="isBigFooter" class="footer-subscribe">
        <div class="subscribe-title">
          <span>{{ isBigFooter }}</span>
          <span>Получите консультацию</span>
        </div>
        <p class="subscribe-text">
          Если у вас возникли вопросы или вы не знаете, с чего начать, оставьте
          свои контактные данные. Наши специалисты свяжутся с вами в ближайшее
          время.
        </p>
        <div class="btn-action">
          <button
              @click="openConsultModal = true"
              type="button"
              class="btn btn-o-white"
          >
            Бесплатная консультация
          </button>
          <!-- <button @click="openDemoModal = true" class="btn btn-l-white">Заказать демо платформы</button> -->
        </div>
      </div>

      <div class="footer-desktop">
        <div class="footer-grid display--grid">
          <div>
            <ul>
              <li>
                <router-link to="/products/all">Продукты</router-link>
              </li>
              <li>
                <router-link to="/contacts">О нас</router-link>
              </li>
              <li>
                <router-link to="/methodology">Методология</router-link>
              </li>
              <li>
                <router-link to="/register/stati">Тестирование</router-link>
              </li>
              <li>
                <router-link to="/geography">География</router-link>
              </li>
              <li>
                <router-link to="/media-center">Новости</router-link>
              </li>
              <li>
                <router-link to="/register/stati">Рассказ ЭИ</router-link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li v-for="productType in generalTags" :key="productType.id">
                <router-link :to="`/products/${productType.title}`"
                >{{ productType.title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <router-link to="/document/privacy_policy"
                >Политика конфиденциальности
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/offer">Договор оферты</router-link>
              </li>
              <li>
                <router-link to="/document/consent"
                >Согласие на обработку персональных данных
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/payment">Как оплатить</router-link>
              </li>
              <li>
                <router-link to="/document/info"
                >Сведения об образовательной организации
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/documentation"
                >Документы
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/mailing"
                >Согласие на рекламную рассылку
                </router-link
                >
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li><a href="tel:+74959717302">+7 (495) 971-73-02</a></li>
              <li>
                <a :href="`mailto:${email}`">{{ email }}</a>
              </li>
            </ul>
            <p>
              ООО «Лаборатория ЭИ»<br/>
              ИНН 7703438337<br/>
              ОГРН 5177746280168<br/>
              121205, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ МОЖАЙСКИЙ,<br/>
              ТЕР СКОЛКОВО ИННОВАЦИОННОГО ЦЕНТРА, Б-Р БОЛЬШОЙ, Д. 42 СТР.1, ЭТАЖ
              4, ПОМЕЩ. 88
            </p>
          </div>
        </div>
        <div class="footer-social display--flex">
          <a href="https://t.me/way2wei"
          ><img src="@/assets/images/telegram.svg" alt=""
          /></a>
          <a href="https://vk.com/way2wei"
          ><img src="@/assets/images/vk.svg" alt=""
          /></a>

          <img
              @click="redirectToZen"
              class="mb-5 img-a"
              src="@/assets/images/Zen.svg"
              alt=""
          />
          <a href="https://www.youtube.com/channel/UCfT273i6xUZpr2nX9XDoh8A"
          ><img src="@/assets/images/Youtube.svg" alt=""
          /></a>
        </div>
        <div class="text">
            WAY2WEI (Way to Working Emotional Intelligence)
              - платформа оценки и развития эмоционального интеллекта человека и корпорации как фундамента гибких
              навыков.
              Включена в реестр российского ПО
            <a href="https://reestr.digital.gov.ru/reestr/1284794/?sphrase_id=4394531" target="_blank" class="registry-link">(Nº записи 16319)</a>
        </div>
        <div class="footer-bottom display--grid">
          <div class="copyright">
            <div class="copyright-title">
              © {{ thisYear }} WAY2WEI защищен законом об авторском праве
            </div>
            <p class="copyright-text">
              Никакая его часть не подлежит полному или частичному копированию,
              фотокопированию, воспроизведению, переводу, записи на какой-либо
              носитель без предварительного согласия ООО&nbsp;«Лаборатория ЭИ».
            </p>
          </div>
          <div class="skolkovo display--flex">
            <a target="_blank" href="https://sk.ru/"><img src="@/assets/images/skolkovo-logo.svg" alt=""/></a>
            <p>
              <span>Исследования осуществляются</span>
              <span>при грантовой поддержке</span>
              <span>Фонда «Сколково»</span>
            </p>
          </div>
        </div>
      </div>
      <!-- МОБИЛЬНАЯ ВЕРСИЯ -->
      <div class="footer-mobile">
        <div class="footer-mobile--grid display--grid">
          <div>
            <a href="tel:74959717302" class="footer-link display--flex">
              <span class="svg-wrap display--flex">
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                  <path
                      d="M5.2,20.6c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.3l0-3.3c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.8-0.5,1.2-0.5l3.2-0.1
                    c0.5,0,1,0.2,1.4,0.6l1.3,1.4c1.2-0.7,3.9-3.4,4.6-4.6l-1.3-1.1c-0.4-0.3-0.6-0.9-0.6-1.4l0.1-3.3c0-0.5,0.2-0.9,0.5-1.2
                    c0.3-0.3,0.8-0.5,1.2-0.5l3.4,0c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2v1.7c0,5.6-7.1,13.6-13.7,13.7L5.2,20.6
                    C5.2,20.6,5.2,20.6,5.2,20.6z M8.4,14.8C8.4,14.8,8.4,14.8,8.4,14.8l-3.2,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4
                    l0,3.3c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2h1.7c5.7-0.1,12.5-7.7,12.5-12.5V5.2c0-0.2-0.1-0.3-0.2-0.4
                    c-0.1-0.1-0.2-0.2-0.4-0.2l-3.4,0c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.4l-0.1,3.3c0,0.2,0.1,0.3,0.2,0.4l2,1.7L16.9,11
                    c-0.4,1.4-4.5,5.5-5.9,5.9L10.6,17l-1.8-2C8.7,14.9,8.5,14.8,8.4,14.8z"
                  />
                </svg>
              </span>
              <span>+7 (495) 971-73-02</span>
            </a>

            <ul>
              <li>
                <router-link to="/products/all">Продукты</router-link>
              </li>
              <li>
                <router-link to="/contacts">О нас</router-link>
              </li>
              <li>
                <router-link to="/methodology">Методология</router-link>
              </li>
              <li>
                <router-link to="/register/stati">Тестирование</router-link>
              </li>
              <li>
                <router-link to="/geography">География</router-link>
              </li>
              <li>
                <router-link to="/media-center">Новости</router-link>
              </li>
              <li>
                <router-link to="/register/stati">Рассказ ЭИ</router-link>
              </li>
            </ul>
            <div class="skolkovo display--flex">
              <img src="@/assets/images/skolkovo-logo.svg" alt=""/>
              <p>
                <span>Исследования осуществляются</span>
                <span>при грантовой поддержке</span>
                <span>Фонда «Сколково»</span>
              </p>
            </div>
          </div>
          <div>
            <a href="mailto:connect@eilab.ru" class="footer-link display--flex">
              <span class="svg-wrap display--flex">
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                >
                  <path
                      d="M5.7,6.9h12.6c1.1,0,2,0.9,2,2v6.9c0,1.1-0.9,2-2,2H5.7c-1.1,0-2-0.9-2-2V8.9C3.6,7.8,4.6,6.9,5.7,6.9z"
                  />
                  <path d="M19.7,7.5l-4.1,3.8c-2.1,2-5.4,2-7.5,0l-4-3.8"/>
                  <path d="M4.1,17.1l4.8-4.8 M19.8,17.2l-5.1-5.1"/>
                </svg>
              </span>
              <span>connect@eilab.ru</span>
            </a>
            <ul>
              <li v-for="productType in generalTags" :key="productType.id">
                <router-link :to="`/products/${productType.title}`"
                >{{ productType.title }}
                </router-link>
              </li>
            </ul>
            <ul class="list-xs">
              <li>
                <router-link to="/document/privacy_policy"
                >Политика конфиденциальности
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/offer">Договор оферты</router-link>
              </li>
              <li>
                <router-link to="/document/consent"
                >Согласие на обработку персональных данных
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/payment">Как оплатить</router-link>
              </li>
              <li>
                <router-link to="/document/info"
                >Сведения об образовательной организации
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/documentation"
                >Документы
                </router-link
                >
              </li>
              <li>
                <router-link to="/document/mailing"
                >Согласие на рекламную рассылку
                </router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-mobile--social display--flex">
          <a href="https://t.me/way2wei"
          ><img src="@/assets/images/telegram.svg" alt=""
          /></a>
          <a href="https://vk.com/way2wei"
          ><img src="@/assets/images/vk.svg" alt=""
          /></a>

          <img
              @click="redirectToZen"
              class="mb-5 img-a"
              src="@/assets/images/Zen.svg"
              alt=""
          />
          <a href="https://www.youtube.com/channel/UCfT273i6xUZpr2nX9XDoh8A"
          ><img src="@/assets/images/Youtube.svg" alt=""
          /></a>
        </div>
        <div class="text">
          WAY2WEI (Way to Working Emotional Intelligence)
          - платформа оценки и развития эмоционального интеллекта человека и корпорации как фундамента гибких
          навыков.
          Включена в реестр российского ПО
          <a href="https://reestr.digital.gov.ru/reestr/1284794/?sphrase_id=4394531" target="_blank" class="registry-link">(Nº записи 16319)</a>
        </div>
        <div class="footer-mobile--bottom">
          <p>
            ООО «Лаборатория ЭИ»<br/>
            ИНН 7703438337<br/>
            ОГРН 5177746280168<br/>
            121205, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ МОЖАЙСКИЙ,<br/>
            ТЕР СКОЛКОВО ИННОВАЦИОННОГО ЦЕНТРА, Б-Р БОЛЬШОЙ, Д. 42 СТР.1, ЭТАЖ
            4, ПОМЕЩ. 88
          </p>
          <div class="copyright">
            <div class="copyright-title">
              © {{ thisYear }} WAY2WEI защищен законом об авторском праве
            </div>
            <p class="copyright-text">
              Никакая его часть не подлежит полному или частичному копированию,
              фотокопированию, воспроизведению, переводу, записи на какой-либо
              носитель без предварительного согласи ООО&nbsp;«Лаборатория ЭИ».
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <pop-up-form
      type="ConsultFooter"
      commentField
      title="Получить консультацию"
      v-if="openConsultModal"
      @closeModal="openConsultModal = false"
  />
  <pop-up-form
      postField
      companyField
      title="Заказать демо платформы"
      type="Demo"
      v-if="openDemoModal"
      @closeModal="openDemoModal = false"
  />
</template>

<script>
import {ref} from "@vue/reactivity";
import {computed} from "@vue/runtime-core";
import PopUpForm from "../pop-up-form/PopUpForm.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
  components: {PopUpForm},
  name: "ei-footer",

  setup() {
    const store = useStore(),
        route = useRoute();

    let openConsultModal = ref(false),
        openDemoModal = ref(false),
        phone = "+7 (495) 971-73-02",
        email = "connect@eilab.ru",
        curentPage = ref(route.path);

    let thisYear = computed(() => new Date().getFullYear()),
        generalTags = computed(() => store.state.products.generalTags),
        isBigFooter = computed(() => {
          switch (route.path) {
            case "/methodology":
            case "/cart":
            case "/order":
            case "/contacts":
            case "/document":
              return false;
            case "/":
              return "Хотите подключиться к платформе?";
            case "/catalog":
              return "Не можете определиться с выбором?";
            default:
              return "Остались вопросы?";
          }
        });
    let redirectToZen = () => {
      location.href = "https://zen.yandex.ru/id/5e68e3984964e13c52f111cf";
    };

    return {
      openConsultModal,
      openDemoModal,
      phone,
      email,
      thisYear,
      generalTags,
      curentPage,
      isBigFooter,
      redirectToZen,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 85px 0 96px;
  color: #ffffff;
  background: linear-gradient(180deg, #313554 0%, #3e4262 100%);
}

.footer-subscribe {
  margin-bottom: 45px;
  padding-bottom: 170px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.subscribe-title {
  margin-bottom: 24px;
  font-size: 46px;
  line-height: 51px;
}

.subscribe-title span {
  display: block;
}

.subscribe-text {
  max-width: 75%;
  margin: 0;
  font-size: 28px;
  line-height: 34px;
}

.footer-subscribe .btn-action {
  margin-top: 30px;
}

.footer-grid {
  grid-template-columns: 2fr 3fr 3fr 4fr;
  grid-gap: var(--gap);
  padding: 0 0 20px;
}

.footer-grid ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-grid li {
  cursor: pointer;
}

.footer-grid li + li {
  margin-top: 8px;
}

.footer-grid a {
  color: #ffffff;
  font-size: 18px;
  line-height: 20px;
}

.footer-grid a:hover {
  opacity: 0.5;
}

.footer-grid p {
  margin: 0;
  font-size: 14px;
  line-height: 22px;
}

.footer-grid ul + p {
  margin-top: 13px;
}

.footer-social {
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.img-a {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.img-a:hover {
  opacity: 0.5;
}

.footer-social img {
  margin-right: 12px;
}

.footer-social a:hover {
  opacity: 0.5;
}

.footer-bottom {
  grid-template-columns: 7fr 5fr;
  grid-gap: var(--gap);
  padding: 45px 0 0;
}

.copyright-title {
  font-size: 16px;
  line-height: 30px;
}

.copyright-text {
  margin: 0;
  font-size: 13px;
  line-height: 18px;
}

.skolkovo {
  align-items: center;
  justify-content: flex-end;
}

.skolkovo a {
  display: block;
}

.skolkovo img {
  width: 120px;
  margin-right: 20px;
}

.skolkovo p {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
}

.skolkovo p span {
  display: block;
}

.registry-link {
  color: #ffffff !important;
  text-decoration-line: underline;
  text-underline-position: auto;
  text-underline-offset: .125em;
}

@media (min-width: 768px) and (max-width: 1100px) {
  .subscribe-text {
    max-width: 100%;
  }
}

@media (min-width: 767px) {
  .footer-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  footer {
    padding: 40px 0 40px;
  }

  .footer-subscribe {
    margin-bottom: 24px;
    padding-bottom: 150px;
  }

  .subscribe-title {
    font-size: 36px;
    line-height: 43px;
  }

  .subscribe-text {
    max-width: 100%;
    font-size: 18px;
    line-height: 22px;
  }

  .footer-subscribe .btn-action .btn {
    justify-content: center;
    padding: 5px 14px 7px;
    font-size: 18px;
  }

  .footer-subscribe .btn-action .btn + .btn {
    margin-top: 0;
    margin-left: 0;
  }

  .footer-desktop {
    display: none !important;
  }

  .footer-mobile a {
    color: #ffffff;
    text-decoration: none;
  }

  .footer-mobile--grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .footer-link {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }

  .footer-link a {
    color: #ffffff;
    text-decoration: none;
  }

  .footer-link .svg-wrap {
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }

  .footer-link svg {
    width: 48px;
    height: 48px;
  }

  .footer-mobile ul {
    margin: 0 0 24px 0;
    padding: 0;
    font-size: 14px;
    line-height: 19px;
    list-style: none;
  }

  .footer-mobile ul li + li {
    margin-top: 8px;
  }

  .footer-mobile ul + ul {
    font-size: 12px;
    line-height: 15px;
  }

  .skolkovo {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 24px;
  }

  .skolkovo img {
    height: 48px;
    margin-bottom: 8px;
  }

  .skolkovo p {
    font-size: 10px;
    line-height: 13px;
  }

  .footer-mobile--social {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .footer-mobile--social img {
    margin-right: 8px;
  }

  .footer-mobile--bottom,
  .copyright-title {
    font-size: 10px;
    line-height: 17px;
  }
}

@media (max-width: 550px) {
  .footer-subscribe .btn-action .btn + .btn {
    margin-top: 20px;
  }
}
</style>
