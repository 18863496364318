export default (type) => {
	switch (type.value) {
		case "ConsultMain":
		case "ConsultTasks":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "consultation_main");
			break;
		case "ConsultProduct":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "product_consultation");
			break;
		case "ConsultFooter":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "consultation_free_futer");
			break;
		case "Demo":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "demo_platforma");
			break;
		case "new_partner":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "new_partner");
			break;
		case "Diagnostic":
			// eslint-disable-next-line no-undef
			ym(61610965, "reachGoal", "diagnostic_kit");
			break;
	}
}