import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import {VueReCaptcha} from "vue-recaptcha-v3";
import {createHead} from '@vueuse/head'
import VueGtag from "vue-gtag";
import VueYandexMetrika from 'vue-yandex-metrika'
import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

const head = createHead()

router.afterEach(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
})

const VueTelInputOptions = {
    mode: "international",
    onlyCountries: ['RU', 'AL', 'AM', 'AZ', 'BG', 'BY', 'CZ', 'EE', 'GE', 'HU', 'KG', 'KZ', 'LT', 'LV', 'MD', 'ME', 'PL', 'RO', 'TJ', 'TM', 'UA', 'UZ'],
    preferredCountries: ['RU'],
    autoDefaultCountry: true,
    defaultCountry: 'RU',
    autoFormat: true,
    inputOptions: {
        placeholder: "",
        showDialCode: true,
    },
    validCharactersOnly: true
}


createApp(App).use(store).use(router).use(head).use(VueTelInput, VueTelInputOptions)
    .use(VueYandexMetrika, {
        id: 61610965,
        router: router,
        env: process.env.NODE_ENV,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
    })
    .use(VueReCaptcha, {siteKey: '6LcbzIQdAAAAAFbPq4VMwBD90rDL6-GJouc9jF8h'}).use(VueGtag, {
    config: {id: "UA-215690368-1"}
}).mount("#app");
